import React from "react";
import { Layout, Row, Col, Collapse } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import GuideSider from "../../components/guide/guide-sider";

import css from "../../styles/guide.css";

const Panel = Collapse.Panel;

class MobileHelp extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="The phone version of DayViewer is an app you create simply by adding to your home screen. It makes everything work more easily on the go."
            />
            <title>DayViewer Online Planner Guide - Phone</title>
          </Helmet>
          <Header />
          <Layout style={{ minHeight: "100vh" }}>
            <GuideSider />
            <Layout className="guide-body">
              <h1>DayViewer Mobile Version</h1>
              <Row>
                <Col>
                  <Collapse>
                    <Panel header="Appstore and Play Store " key="1">
                      <p>
                        DayViewer is now generally available on Apple Appstore
                        and Google Play Store. Simply search in the appropriate
                        store and download the latest version.
                      </p>
                      <p>
                        The app version is built in conjunction with the web
                        version, but it is important to know that pricing is
                        based off GB Pound Sterling value instead of US dollars,
                        and all orders will be handled completely by the app
                        store according to their pricing structure. Pricing will
                        generally update as we update the web version pricing.
                      </p>
                      <p>
                        Team Rooms in DayViewer cannot be purchased on the app
                        stores and must be administered from within the web app
                        or PWA app (see below). However this does not extend to
                        the usability of team planner in both app and PWA
                        versions which should be a similar experience.
                      </p>
                    </Panel>
                    <Panel
                      header="Alternative Way To Setup DayViewer On Your Phone / Tablet"
                      key="2"
                    >
                      <p>
                        DayViewer is a PWA (which stands for Progressive Web
                        App) it basically means the mobile app version can be
                        created instantly by going to your browser and adding
                        DayViewer to your home screen.
                      </p>
                      <p>
                        This method of installion bypasses the Appstores and
                        Google Play stores but works just like the apps.
                      </p>
                      <p>
                        The DayViewer design works similarly on both large and
                        small screens and has been carefully designed to meet
                        the requirements for various devices.
                      </p>
                      <p>
                        Depending on the browser used, please follow the setup
                        instructions as appropriate.
                      </p>
                    </Panel>
                    <Panel
                      header="Setup for Android Devices using Chrome Browser"
                      key="3"
                    >
                      <p>
                        Open Chrome Browser and navigate to{" "}
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          https://app.dayviewer.com
                        </a>{" "}
                        once loaded, from your browser menu, click Add to Home
                        Screen and the app icon will appear on your phone or
                        tablet home screen.
                      </p>

                      <p>
                        Double click to open the app and login with your
                        credentials.
                      </p>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/addtohomescreenAndroid.png"
                          width="300"
                        />
                      </div>
                    </Panel>
                    <Panel
                      header="Setup for iOS Devices using Safari Browser"
                      key="4"
                    >
                      <p>
                        Open Safari Browser and navigate to{" "}
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          https://app.dayviewer.com
                        </a>{" "}
                        once loaded, from your browser menu, click Add to Home
                        Screen and the app icon will appear on your phone or
                        tablet home screen.
                      </p>

                      <p>
                        Double click to open the app and login with your
                        credentials.
                      </p>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/addtohomescreenIOS.png"
                          width="300"
                        />
                      </div>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Layout>
          </Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default MobileHelp;
